export const endpoints = {
  login: "https://www.gumlet.com/org/data/",
  user: "/user/data",
  userPass: "/user/password/reset",
  org: "/org/data",
  monthlySummary: "/org/monthly-summary",
  finish_onboarding: "/org/finish-onboarding",
  properties: {
    base: "/video/viewer-insights/property",
    addProperty: "/video/viewer-insights/property",
    editProperty: "/video/viewer-insights/property/{id}",
    deleteProperty: "/video/viewer-insights/property/{id}",
  },
  views: {
    base: "/video/viewer-analytics",
    viewsDetail:
      "/video/viewer-analytics/events?playback_id={id}&session_id={id}&columns=*&player_instance_id={id}",
  },
  started: "video/viewer-insights/property/integration?source_player={player}",
  getFilters:
    "video/viewer-analytics/filters?project_id={id}&start_at={start}&end_at={end}",
  integrations: "video/viewer-insights/property/{id}/third-party-integrations",
  dashboards: {
    reorder: "/insights-dashboard/reorder",
    add: "/insights-dashboard",
    get: "/insights-dashboard?property_id={id}",
    edit: "/insights-dashboard/{id}",
    delete: "/insights-dashboard/{id}",
    copyDashboard: "/insights-dashboard/dashboard/:dashboard_id/copy",
  },
  reports: {
    reorder: "/insights-dashboard/{id}/report/reorder",
    addReport: "/insights-dashboard/{id}/report",
    singleReport: "/insights-dashboard/{id}/report/{report_id}",
    editReport: "/insights-dashboard/{id}/report/{report_id}",
    copyReport: "/insights-dashboard/report/:report_id/copy",
  },
  image: {
    sources: {
      base: "/image/sources",
      getsourcebyid: "/image/sources/:source_id",
      parameters: "/image/sources/:id",
    },
    alerts: {
      base: "/image/alerts",
      getalertbyid: "/image/alerts/:alert_id",
      alertAction: "/image/alerts/:id",
    },
    reports: {
      base: "/image/reports",
      getreportbyid: "/image/reports/:report_id",
      reportAction: "/image/reports/:id",
    },
    purgeCache: "/purge/",
    analytics: "/image/analytics",
    exportLogs: "/image/analytics/export_4xx",
    customdomain: {
      verifyCname: "/image/cname",
      deleteCname: "/image/cname/:id/:cname",
    },
  },
  video: {
    analytics: "/video/analytics",
    sources: {
      base: "/video/sources",
      minimalData: "/video/sources?minimal_data=true",
      getsourcebyid: "/video/sources/:source_id",
      getSourceStatbyid: "/video/sources/:source_id/stats",
    },
    profiles: {
      base: "/video/profiles",
      getprofilebyid: "/video/profiles/:profile_id",
    },
    alerts: {
      base: "/video/alerts",
      getalertbyid: "/video/alerts/:alert_id",
      alertAction: "/video/alerts/:id",
    },
    reports: {
      base: "/video/reports",
      getreportbyid: "/video/reports/:report_id",
      reportAction: "/video/reports/:id",
    },
    events: {
      base: "/video/sources/events",
      getById: "/video/sources/events/:id",
    },
    assets: {
      events: "/video/assets/event/:id",
    },
    playlist: {
      base: "/video/playlist?collection_id=:id",
      create: "/video/playlist",
      move: "/video/playlist/:id/asset",
      getPlaylistByID: "/video/playlist/:id",
      getAllPlaylistAssets: "/video/assets/list/:id",
      deletePlaylistAsset: "/video/playlist/:id/asset",
    },
    cms: {
      getDataByCollection: "/video/assets/list/:id",
    },
    asset: {
      assetAnalytics: "/video/assets/:asset_id/analytics",
      update: "/video/assets/update",
      deleteAsset: "/video/assets/bulk/delete",
      addFolder: "/video/sources/:id/folder",
      moveToFolder: "/video/assets/bulk/move",
      renameFolder: "/video/sources/:id/folder/rename",
      manageTags: "/video/assets/bulk/tag",
      getAssetDetails: "/video/assets/:asset_id",
      statusHistory: "/video/assets/:asset_id/history",
      upload_thumbnail: "/video/assets/:asset_id/thumbnail",
      createAssetUpload: "/video/assets/upload",
      getSignUrlPart:
        "/video/assets/:asset_id/multipartupload/:part_number/sign",
      completeMultiPartUpload:
        "/video/assets/:asset_id/multipartupload/complete",
      createAsset: "/video/assets",
      addChapters: "/video/assets/:asset_id/chapters",
      getSubtitles: "/video/assets/:asset_id/subtitle/:file_name",
      addSubtitles: "/video/assets/:asset_id/subtitle/:file_name",
      uploadLogo: "/video/sources/:collection_id/channel/logo",
      uploadSubtitles: "/video/assets/:asset_id/subtitle/upload",
      uploadEvent: "/video/assets/:asset_id/subtitle/upload/event",
      gdCompanion: "/video/companion/upload",
    },
    importers: {
      connect: "/video/migration/external-fetch-data",
      importVideos: "/video/migration",
      disconnect: "video/migration/disconnect",
      youtubeConnect: "video/migration/youtube",
      zoomConnect: "video/sources/zoom/login?collectionId=:id",
      vimeoConnect: "video/migration/vimeo",
    },
    customdomain: {
      verifyCname: "/video/sources/:id/channel/cname",
      deleteCname: "/video/sources/:id/channel/cname",
    },
    downaloadCsv: "/video/viewer-analytics/download",
    channelMembers: {
      addUser: "channel/:id/viewers/invite",
      getUsers: "channel/:id/viewers",
      removeUser: "channel/:id/viewers/remove",
      reInvite: "channel/:id/viewers/resend-invite",
      csvInvite: "channel/:id/viewers/invite/csv",
    },
  },

  stream: {
    sources: {
      base: "/video/sources/live",
      getLiveSource: "/video/sources/live/:source_id",
      delete: "/video/sources/live/:source_id",
    },
    asset: {
      base: "/video/live/assets",
      getLiveAsset: "/video/live/assets/:asset_id",
      getLiveAssetList: "/video/live/assets/list/:collection_id",
      getLiveAnalytics: "/video/live/assets/stream_stats",
      getLiveAssethistory: "/video/live/assets/:asset_id/history",
      completeStream: "/video/live/assets/:asset_id/complete",
      delete: "/video/live/assets/:asset_id",
    },
  },

  organization: {
    account: {
      base: "/core/subuser",
      add: "/core/subuser",
      edit: "/core/subuser/:id",
      getaccountbyid: "/core/subuser/:account_id",
    },
    webhook: {
      base: "/org/webhooks",
      add: "/org/webhooks",
      edit: "/org/webhooks/:id",
      getwebookbyid: "/org/webhooks/:webhook_id",
      getHistory: "/org/webhook/:webhook_id/history",
    },
    drm: {
      robust: "/org/widevine_robustness",
      widevine: {
        base: "/org/widevine_credentials",
        generate: "/org/regenerate_widevine_credentials",
      },
      fairplay: {
        base: "/org/fairplay_credentials",
      },
    },
    coupon: {
      base: "/core/coupons",
      add: "/core/coupons/redeem",
    },
    billing: {
      base: "/billing",
      details: "/billing/details",
      upcomingInvoice: "/billing/invoice/upcoming",
      invoices: "/billing/invoice/history",
      alerts: {
        base: "/billing/alerts",
        add: "/billing/alerts/:alert_id",
        delete: "/billing/alerts/:alert_id",
      },
      card: {
        base: "/billing/card",
        delete: "/billing/card/:card_id",
        default: "/billing/card/default",
      },
      stripe: {
        intent: "/billing/setup_intent",
      },
      subscriptions: "/billing/subscriptions/start",
      plan: "/billing/plan",
      startup_credits: "/billing/credit",
    },
    profile: {
      userSearch: "/user/search",
      enableTwoFactor: "/user/2fa/enable",
      verifyTwoFactor: "/user/2fa/verify",
      disableTwoFactor: "/user/2fa/disable",
    },
  },
  userToken: {
    apikey: {
      base: "/user/token",
      add: "/user/token",
      delete: "/user/token/:token_id",
      legacy: "/org/api_keys",
      deleteLegacy: "/org/api_keys/:key_id",
    },
    session: {
      getSessions: "/user/sessions",
      deleteSession: "/user/sessions/:session_id",
      deleteAllSessions: "/user/sessions",
    },
  },
  getEmbedAssets: "/v1/asset/:asset_id?minimal=true",
};
